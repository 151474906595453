<template>
    <div>
        <v-card :elevation="1" class="v-sheet theme--light br-0">
        <div class="row mt-0 mb-4">
          <div class="col-12 col-md-12 m-0 p-0" style="margin-top: -5px !important;">
            <DivisorColor  :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`"/>
          </div>
      </div>
            <v-card-title>

                <v-row class="col-md-12">
                    <v-col cols="12" sm="12" md="12">
                        <p class="text-h5 font-weight-black mb-0">Búsqueda de documentos de proyectos </p>
                        <v-subheader class="pl-0 font-weight-black text-subtitle-1 text--blue-grey text--lighten-4">
                            IAFF/Convenios/Dictámenes técnicos
                        </v-subheader>
                    </v-col>
                </v-row>

                <v-row class="col-md-12 m-0 p-0">
                <v-divider></v-divider>
                </v-row>
            </v-card-title>
            <v-card-text class="pl-0 pr-0">
                <v-container class="p-8">
                    <v-form 
                        ref="formDatosFinancieros"
                        v-on:submit.prevent="buscarProyectos"
                        v-model="validFormBusqueda"
                    >
                        <v-row>
                            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                                <v-select
                                    :clearable="true"
                                    v-model="documentoBusquedaSeleccionado"
                                    :items="documentosBusqueda"
                                    :loading="false"
                                    dense
                                    filled
                                    label="Seleccione el documento que desea generar"
                                    :no-data-text="'No existen documentos para seleccionar'"
                                    menu-props="auto"
                                    return-object
                                    :rules="[
                                        selectRequired('documento')
                                    ]"
                                ></v-select>
                                
                            </v-col>
                        
                            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                                <v-select
                                    :clearable="true"
                                    v-model="opcionBusquedaSeleccionada"
                                    :items="opcionesBusqueda"
                                    :loading="false"
                                    dense
                                    filled
                                    label="Opción de búsqueda"
                                    :no-data-text="'No existen opciones de proyecto'"
                                    menu-props="auto"
                                    return-object
                                     :rules="[
                                        selectRequired('opción de búsqueda')
                                    ]"
                                ></v-select>
                                
                            </v-col>

                            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                                <v-text-field
                                    :clearable="true"
                                    filled
                                    autocomplete="off"
                                    dense
                                    :disabled="!opcionBusquedaSeleccionada.id"
                                    v-model="filtro"
                                    :label="opcionBusquedaSeleccionada.id && opcionBusquedaSeleccionada.id === 1 ? `Ingrese el nombre del proyecto` : 
                                            opcionBusquedaSeleccionada.id && opcionBusquedaSeleccionada.id === 2 ? `Ingrese el código del proyecto` : 
                                            opcionBusquedaSeleccionada.id && opcionBusquedaSeleccionada.id === 3 ? `Ingrese nombre de la fuente cooperante` :
                                            opcionBusquedaSeleccionada.id && opcionBusquedaSeleccionada.id === 4 ? `Ingrese la fecha de suscripción` :
                                            opcionBusquedaSeleccionada.id && opcionBusquedaSeleccionada.id === 5 ? `Ingrese la fecha de inicio` : 
                                            `Seleccione una opción de búsqueda`"
                                    :rules="[
                                        required('texto de búsqueda')
                                    ]"
                                    maxlength="100"
                                >
                                    
                                </v-text-field>
                                
                            </v-col>
                        </v-row>

                        <v-row>

                            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                                <v-select
                                    :clearable="true"
                                    v-model="datosConsulta.periodosId"
                                    :items="periodos"
                                    :loading="ddPeriodosLoading"
                                    :disabled="documentoBusquedaSeleccionado.id > 2" 
                                    dense
                                    filled  
                                    :label="!documentoBusquedaSeleccionado.id ? `Año` : `Año`"
                                    item-text="periodo"
                                    item-value="id"
                                    :rules="[
                                        documentoBusquedaSeleccionado.id < 3 ? selectRequired('periodo / año') : true,
                                    ]"
                                    :no-data-text="
                                        tiposDatosFinancieros != null
                                        ? 'Selecciona un periodo'
                                        : 'No se han encontrado periodos'
                                    "
                                    menu-props="auto"
                                ></v-select>                                
                            </v-col>

                            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                                <v-select
                                    :clearable="true"
                                    v-model="bimestreSeleccionado"
                                    :disabled="documentoBusquedaSeleccionado.id > 2"
                                    :items="bimestres"
                                    :loading="false"
                                    dense
                                    filled
                                    :label="!documentoBusquedaSeleccionado.id ? `Bimestre` : `Bimestre`"
                                    :no-data-text="'No existen bimestres registrados'"
                                    menu-props="auto"
                                    return-object
                                    :rules="[
                                        documentoBusquedaSeleccionado.id < 3 ? selectRequired('bimestre') : true
                                    ]"
                                ></v-select>
                            </v-col>

                            <v-col cols="12" md="4" sm="12" xs="12" class="pt-0 pb-0">
                                <v-btn
                                    class="white--text float-right"
                                    large
                                    color="light-blue-502"
                                    :loading="btnBusquedaLoading"
                                    :disabled="!validFormBusqueda"
                                    type="submit"
                                >
                                    <v-icon left>mdi-magnify</v-icon> Buscar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>

                <v-data-table 
                    class="elevation-0 mt-12"
                    :headers="headers"
                    :items="proyectos"
                    :search="filtro"
                    :loading="tableLoading"
                    :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-page-first',
                        lastIcon: 'mdi-page-last',
                        prevIcon: 'mdi-chevron-left',
                        nextIcon: 'mdi-chevron-right',
                        'items-per-page-text': 'Registros por página',
                        pageText: '{0}-{1} de {2}'
                    }"
                    >
                    <!-- <template v-slot:top>
                       
                        <v-container fluid>
                        
                        </v-container>
                    </template> -->

                    <template v-slot:item="{ item }" class="mt-4">
                        <tr>
                            <td>{{ item.codigo }}</td>
                            <td>{{ item.nombreProyecto }}</td>
                            <td>{{ item.fuenteCooperante }}, {{item.agencia}}</td>

                            <td>{{ item.institucion }}</td>
                            <td>
                                <v-btn
                                    v-if="documentoBusquedaSeleccionado.id ===1"
                                    class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                                    small
                                    depressed
                                    color="blue-grey lighten-5"
                                    >
                                    <v-icon left>mdi-cloud-download</v-icon> 
                                        Descargar IAFF
                                </v-btn>

                                <v-btn
                                    v-if="documentoBusquedaSeleccionado.id ===2"
                                    class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                                    small
                                    depressed
                                    color="blue-grey lighten-5"
                                    >
                                    <v-icon left>mdi-cloud-download</v-icon> 
                                        Descargar Resumen ejecutivo IAFF
                                </v-btn>

                                <v-btn
                                    v-if="documentoBusquedaSeleccionado.id ===3"
                                    class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                                    small
                                    depressed
                                    color="blue-grey lighten-5"
                                    >
                                    <v-icon left>mdi-cloud-download</v-icon> 
                                        Descargar Convenio
                                </v-btn>

                                <v-btn
                                    v-if="documentoBusquedaSeleccionado.id ===4"
                                    class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                                    small
                                    depressed
                                    color="blue-grey lighten-5"
                                    >
                                    <v-icon left>mdi-cloud-download</v-icon> 
                                        Descargar Dictamen técnico
                                </v-btn>

                                <v-btn
                                    v-if="documentoBusquedaSeleccionado.id ===5"
                                    class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                                    small
                                    depressed
                                    color="blue-grey lighten-5"
                                    >
                                    <v-icon left>mdi-cloud-download</v-icon> 
                                        Descargar Autorización cierre
                                </v-btn>

                                <v-btn
                                    v-if="documentoBusquedaSeleccionado.id ===6"
                                    class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                                    small
                                    depressed
                                    color="blue-grey lighten-5"
                                    >
                                    <v-icon left>mdi-cloud-download</v-icon> 
                                        Descargar Autorización suspensión
                                </v-btn>

                                <v-btn
                                    v-if="documentoBusquedaSeleccionado.id ===7"
                                    class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                                    small
                                    depressed
                                    color="blue-grey lighten-5"
                                    >
                                    <v-icon left>mdi-cloud-download</v-icon> 
                                        Descargar Autorización finalización
                                </v-btn>

                                <v-btn
                                    v-if="documentoBusquedaSeleccionado.id ===8"
                                    class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                                    small
                                    depressed
                                    color="blue-grey lighten-5"
                                    >
                                    <v-icon left>mdi-cloud-download</v-icon> 
                                        Descargar Autorización enmiendas
                                </v-btn>

                                <v-btn
                                    v-if="item.marcadoresDoc && item.marcadoresDoc.length>0"
                                    class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                                    small
                                    depressed
                                    color="blue-grey lighten-5"
                                     @click="mostrarMapa(item.marcadoresDoc)"
                                    >
                                    <v-icon left>mdi-map</v-icon> 
                                        Ver en mapa
                                </v-btn>

                                
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

         <!--dialog mapa ubicaciones-->
        <v-dialog v-model="dialogUbicaciones"  transition="scroll-y-transition">
        <v-card>
            <v-card-title>
            <v-spacer></v-spacer>
            <v-btn
                icon
                :disabled="false"
                @click="dialogUbicaciones = false; resetUbicaciones()"
                class="float-right"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-card-title>
            <v-card-text style="letter-spacing: 0.1px !important">
            <GmapMap
                :center="{ lat: 16.062775, lng: -89.3520961 }"
                :zoom="8"
                ref="map"
                id="map"
                style="width: 100%; height: 800px"
            >
                <GmapMarker
                    v-for="(m, index) in marcadores"
                    :key="index"
                    :position="m.position"
                    :clickable="true"
                    :draggable="false"
                    @click="
                        center = m.position;
                        openGmapInfo(index);
                    "
                >
                <gmap-info-window
                    :position="infoWindow.position"
                    :opened="infoWindow.open[index]"
                    @closeclick="closeInfoWindow(index)"
                >
                    <!--<button @click="closeInfoWindow(index)">Close</button>-->
                    <div v-html="infoWindow.template">
                    </div>
                    
                </gmap-info-window> 
                </GmapMarker>
            </GmapMap>
            </v-card-text>
        </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import {OBTENER_ITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";
import validations from "@/core/untils/validations.js";
export default {
    name: "ReporteExternoDocumentosProyecto",
    components: {
        DivisorColor
    },
    data() {
        return{ 
            busqueda: false,
            proyectos: [],
            filtro: "",
            validFormBusqueda:false,
            btnBusquedaLoading: false,
            estadoProyecto: [],
            dialogUbicaciones: false,
            tableLoading: false,
            opcionBusquedaSeleccionada:{},
            opcionesBusqueda: [{id: 1, text: "Nombre del proyecto"}, {id: 2, text: "Código SIGEACI"}/*, {id: 3, text: "Fuente cooperante"}, {id: 4, text: "Nombre de institución"}, {id: 5, text: "Fecha de suscripción"}, {id: 6, text: "Fecha de inicio"}*/],
            documentoBusquedaSeleccionado: {},
            documentosBusqueda: [{id: 1, text: "IAFF"}, {id: 2, text: "Resumen ejecutivo IAFF"}, {id: 3, text: "Convenios"}, {id: 4, text: "Dictamen técnico"}, {id: 5, text: "Autorización cierre"}, {id: 6, text: "Autorización suspensión"}, {id: 7, text: "Autorización finalización"}, {id: 8, text: "Enmiendas"}],
            anio: "",
            periodos: [],
            ddPeriodosLoading: false,
            bimestreSeleccionado: {},
            bimestres: [{id: 1, text: "Primero"}, {id: 2, text: "Segundo"}, {id: 3, text: "Tercero"}, {id: 4, text: "Cuarto"}, {id: 5, text: "Quinto"}, {id: 6, text: "Sexto"}],
            datosConsulta: {
                opcionBusqueda: 0,
                tipoDocumentoId:0,
                texto: "",
                periodosId: 0,
                bimestreId: 0,
                
            },
            marcadores:[],
            infoWindow: {
                open: [],
                position: { lat: 0, lng: 0 },
                template: "",
            },
            ...validations
        }
    },

    methods: {

        //Obtener tipos de datos financieros
        async obtenerPeriodos(){

            this.periodos = [];

            this.ddPeriodosLoading=true;
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Periodos/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.periodos = res.data;

                }
                this.ddPeriodosLoading=false;
            })
            .catch(() => {
                this.periodos = [];
                this.ddPeriodosLoading=false;
            });

        },

        async buscarProyectos(){
            
            this.btnBusquedaLoading=true;
            this.tableLoading=true;
            this.proyectos = [];

            //this.ddPeriodosLoading=true;
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `ReporteGeneralDocumentosProyecto/${this.opcionBusquedaSeleccionada.id}/${this.documentoBusquedaSeleccionado.id}/${this.filtro}/${this.datosConsulta.periodosId}/${this.bimestreSeleccionado.id}`})
            .then(res => {
                if (res.status === 200) {
                    this.proyectos = res.data;
                    this.busqueda = true;
                } else {
                    this.busqueda = false;

                }
                this.tableLoading=false;
                this.btnBusquedaLoading=false;
            })
            .catch(() => {
                this.proyectos = [];
                this.btnBusquedaLoading=false;
                this.tableLoading=false;
            });

        },


        //Mostrar el mapa con el/los puntos del proyecto
        mostrarMapa(marcadores){
            //console.log(marcadores)
            
                 //Rellenar los marcadores con lat y long
            marcadores.forEach((element, index) => {
                //console.log(element)
                if(element.latitud && element.longitud) {
                    this.marcadores.push({position: {lat: parseFloat(element.latitud), lng: parseFloat(element.longitud), monto: parseFloat(element.monto)}});
                }    

                this.infoWindow.open[index] = false;
            });


            

           //console.log(this.marcadores)

            this.dialogUbicaciones=true;
        },


        openGmapInfo(index) {
            //console.log(this.infoWindow.open)

           if(this.infoWindow.open && this.infoWindow.open.length > 0){
            //console.log("tiene datos" + this.infoWindow.open);

            this.infoWindow.open.forEach((element, index) => {
                this.infoWindow.open[index] = false;
            });

            //console.log("Ya se borraron los datos" + this.infoWindow.open)

           }

            const {
                lat,
                lng,
                monto
            } = this.marcadores[index].position;

            this.infoWindow.position = { lat: lat, lng: lng };

            this.infoWindow.template = `
            <div class="container" style="letter-spacing: 0.1px; width:100%;">
                <div class="row">
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                        <b>Monto:</b>
                    </div> <!--end:: col-->
                    <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                        GTQ ${parseFloat(monto).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}
                    </div> <!--end:: col-->
                </div> <!--end:: row-->
            </div> <!-- end:: container-->`;

            this.infoWindow.open[index] = true;
            //console.log(this.infoWindow.open);

            //console.log(this.infoWindow)

        } ,

        closeInfoWindow(index){
            //console.log(index);
            this.infoWindow.open[index] = false;
            //console.log(this.infoWindow)
        },

        resetUbicaciones(){
            this.marcadores=[];
            this.infoWindow= {
                open: [],
                position: { lat: 0, lng: 0 },
                template: "",
            };
        }

    },

    created() {
        this.obtenerPeriodos();
    },

    computed: {
        headers() { 
            return [
                {
                    text: "Código",
                    align: "start",
                    sortable: false,
                    value: "codigo"
                },
                {
                    text: "Nombre Proyecto",
                    align: "start",
                    sortable: true,
                    value: "nombreProyecto"
                },
                {
                    text: "Fuente cooperante",
                    align: "start",
                    sortable: true,
                    value: "fuenteCooperante"
                },
                {
                    text: "Institución beneficiaria",
                    align: "start",
                    sortable: true,
                    value: "institucion"
                },
                {
                    text: "Descargas",
                    align: "start",
                    sortable: false,
                    value: ""
                }
            ]
        }
    },
     mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Inicio", route: "/" },
            { title: "Consultas" },
            { title: "Documentos proyecto" }
        ]);

        // var ciphertext = this.$CryptoJS.AES.encrypt("2", 'KEYADMINCNS2022');
    }
    
}
</script>
